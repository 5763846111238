import type { DispatchesInfo, DispatchState, DispatchInfo } from '../types/businessTypes';
import { DispatchType } from 'root/types/businessTypes';
import { makeAutoObservable, toJS } from 'mobx';

class _DispatchState {
  selectedDispatchType: DispatchType = DispatchType.PICKUP;
  dispatchesInfo: DispatchesInfo = {} as DispatchesInfo;

  constructor() {
    makeAutoObservable(this);
  }

  get dispatchInfo(): DispatchInfo {
    return toJS(this.dispatchesInfo[this.selectedDispatchType]) ?? {};
  }

  init(dispatchState: DispatchState) {
    Object.assign(this, dispatchState);
  }

  update(dispatchType: DispatchType, dispatchInfo: DispatchInfo) {
    const currentDispatchInfo = this.dispatchesInfo[dispatchType];
    this.dispatchesInfo[dispatchType] = { ...currentDispatchInfo, ...dispatchInfo };
    this.selectedDispatchType = dispatchType;
  }

  get configuredDispatchTypes() {
    return (Object.keys(this.dispatchesInfo) as DispatchType[]).filter(
      (dispatchType) => !!this.dispatchesInfo[dispatchType]
    );
  }

  get availableDispatchTypes() {
    return this.configuredDispatchTypes.filter(
      (dispatchType) => !!this.dispatchesInfo[dispatchType]?.selectedTimeSlot
    );
  }

  get hasAvailableDispatches() {
    return this.availableDispatchTypes.length > 0;
  }

  get hasConfiguredDispatches() {
    return this.configuredDispatchTypes.length > 0;
  }

  getShippingDetails() {
    const dispatchInfo = this.dispatchInfo;
    const shouldHaveTimeRange = !dispatchInfo.selectedTimeSlot?.startsNow;
    const timeRange = shouldHaveTimeRange
      ? {
          start: dispatchInfo.selectedTimeSlot!.startTime.toUTC().valueOf(),
          end: dispatchInfo.selectedTimeSlot!.endTime.toUTC().valueOf(),
        }
      : undefined;
    return {
      address: toJS(dispatchInfo.address),
      dispatchType: toJS(this.selectedDispatchType),
      timeRange,
    };
  }

  get state(): DispatchState {
    const state = {
      selectedDispatchType: toJS(this.selectedDispatchType),
      dispatchesInfo: toJS(this.dispatchesInfo),
    };
    return state;
  }

  isDispatchAvailable(dispatchType: DispatchType) {
    return this.availableDispatchTypes.includes(dispatchType);
  }

  isDispatchConfigured(dispatchType: DispatchType) {
    return this.configuredDispatchTypes.includes(dispatchType);
  }
}

export const dispatchState = new _DispatchState();
