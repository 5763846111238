export const SPECS = {
  editCartItem: 'specs.restaurants.editCartItem',
  saveDispatchDetails: 'specs.restaurants.saveDispatchDetails',
  installationRetry: 'specs.restaurants.oloFedInstallationRetry',
  usePopulatedItemInOrdersLiveSite: 'specs.restaurants.usePopulatedItemInOrdersLiveSite',
  skipMemberAreaAutoInstall: 'specs.restaurants.skipMemberAreaAutoInstall',
  menuOrderPanel: 'specs.restaurants.menuOrderPanel',
  myBusinessActions: 'specs.restaurants.myBusinessActions',
  localeDatasetCurrencyFormatting: 'specs.restaurants.localeDatasetCurrencyFormatting',
  multiPages: 'specs.restaurants.oloMultiPages',
} as const;
