import type { FlowEditorSDK, IHttpClient, ReportError } from '@wix/yoshi-flow-editor';
import type { ErrorMonitor } from '@wix/fe-essentials-viewer-platform/error-monitor';
import type { FedopsLogger } from 'root/utils/monitoring/FedopsLogger';
import { getOrdersPageById, syncOrdersPageStorage } from './editor.utils';
import { OperationsClient } from 'root/api/operationClient';

export const registerComponentChangeEvents = async ({
  editorSDK,
  fedopsLogger,
  httpClient,
  pageIdPrefix,
  reportError,
  sentry,
}: {
  editorSDK: FlowEditorSDK;
  fedopsLogger: FedopsLogger;
  httpClient: IHttpClient;
  pageIdPrefix: string;
  sentry: ErrorMonitor;
  reportError: ReportError;
}) => {
  await editorSDK.addEventListener('componentDeleted', async (event) => {
    // @ts-expect-error
    const { componentRef, componentType } = event.detail;
    if (componentType === 'mobile.core.components.Page') {
      fedopsLogger.deleteOLOPageStarted();
      const { ordersPage, numOfPages } = await getOrdersPageById(editorSDK, componentRef.id);
      if (numOfPages === 1) {
        // uninstalling the app, no need to delete the operation
        return;
      }
      const operationId = ordersPage?.tpaPageId?.slice(pageIdPrefix.length + 1);
      if (!operationId) {
        sentry
          ? sentry.captureMessage('Failed to get operationId from pageId', {
              contexts: {
                ordersPage: {
                  name: ordersPage?.title,
                  tpaPageId: ordersPage?.tpaPageId,
                },
              },
            })
          : reportError('Failed to get operationId from pageId');
        return;
      }
      await new OperationsClient(httpClient, fedopsLogger, sentry, reportError).deleteOperation(
        operationId
      );
      await syncOrdersPageStorage(editorSDK);
      fedopsLogger.deleteOLOPageEnded();
    }
  });
};
