import type { WidgetDesignTabsBuilder } from '@wix/app-manifest-builder/dist/types/controller/widgetDesign/widgetDesignTabsBuilder';
import type { WriteOnlyBuilder } from '@wix/app-manifest-builder/dist/types/types';
import type { Item, Section, Menu } from '../types/menusTypes';
import type { WidgetDesignTabsArray } from '../types/widgets';
import type {
  AppManifestBuilder,
  TFunction,
  WidgetBuilder,
  ReportError,
} from '@wix/yoshi-flow-editor';
import type {
  AppManifest,
  ConnectedComponentConfig,
  ControllerStateStageData,
  GfppConfig,
  GfppDesktopConfig,
  WidgetStateStageData,
} from '@wix/platform-editor-sdk';
import {
  DISHES_CONTROLLER_WIDGET_ID,
  HEADER_CONTROLLER_WIDGET_ID,
  LIGHTBOX_CONNECTED_PARAMS,
  MENU_CONTROLLER_WIDGET_ID,
  NAVIGATION_CONTROLLER_WIDGET_ID,
  OLO_CONTROLLER_WIDGET_ID,
  SECTION_CONTROLLER_WIDGET_ID,
} from '../appConsts/consts';
import type { DispatchType } from '../types/businessTypes';
import {
  HEADER_WIDGET_COMPONENT_IDS,
  MENU_WIDGET_COMPONENT_IDS,
  SECTION_WIDGET_COMPONENT_IDS,
} from '../appConsts/blocksIds';
import type { ErrorMonitor } from '@wix/fe-essentials-viewer-platform/error-monitor';

type DataType = Item[] | Section[] | Menu[];

export const getRole = (componentId: string) => {
  return componentId.substring(1);
};

export const doesArrayDataExist = (array?: string[]) => (array?.length ? true : false);

export const hasDataChanged = ({
  prevData = [],
  nextData = [],
}: {
  prevData?: DataType;
  nextData?: DataType;
}) => {
  const isEmptyData = !nextData.length;

  return (
    prevData.length !== nextData.length ||
    isEmptyData ||
    prevData.some(
      (item, idx) => item.id !== nextData[idx].id || item.revision !== nextData[idx].revision
    )
  );
};

export const truncate = (str: string, maxChars: number) => {
  return str.length > maxChars ? `${str.slice(0, maxChars - 1)}...` : str;
};

const addTab = (
  widgetDesignTabsBuilder: WriteOnlyBuilder<WidgetDesignTabsBuilder>,
  label: string,
  roles: string[],
  dependents?: string[]
) => {
  widgetDesignTabsBuilder.addTab((tabBuilder) => {
    tabBuilder
      .set({
        label,
        dependents: dependents?.map(getRole),
      })
      .groups()
      .set({
        roles: roles.map(getRole),
      });
  });
};

export const setWidgetDesignTabs = (
  widgetDesignTabsBuilder: WriteOnlyBuilder<WidgetDesignTabsBuilder>,
  widgetDesignTabsArray: WidgetDesignTabsArray,
  t: TFunction
) => {
  widgetDesignTabsArray.forEach(
    ({ label, roles, dependents }: { label: string; roles: string[]; dependents?: string[] }) => {
      addTab(widgetDesignTabsBuilder, t(label), roles, dependents);
    }
  );
};

export const arrayToStringWithSeparator = (
  array: (number | DispatchType | string)[],
  separator: string
) => {
  return array.join(separator);
};

export const getSortedArrayByIds = <T extends { id: string }>(
  array: T[],
  sortedIds: string[] | undefined
): T[] => {
  if (!sortedIds || sortedIds.length === 0) {
    return array;
  }

  return [...array].sort((item1, item2) => {
    const item1Index = sortedIds.indexOf(item1.id);
    const item2Index = sortedIds.indexOf(item2.id);
    return item1Index === -1 || item1Index > item2Index ? 1 : -1;
  });
};

export const disableWidgetComponentSelection = (
  builder: WidgetBuilder,
  componentId: string,
  selectable = false
) => {
  builder.configureConnectedComponents(getRole(componentId), (titleAndDescriptionBuilder) => {
    titleAndDescriptionBuilder.behavior().set({ closed: { hideFromHierarchy: true, selectable } });
  });
};

const getControllerWidgetById = (appManifest: AppManifest, id: string) => {
  return appManifest.controllersStageData![id].default;
};

export const setLightboxGfpp = (
  appManifestBuilder: AppManifestBuilder,
  id: string,
  displayName: string
) => {
  appManifestBuilder.configureController(
    `${LIGHTBOX_CONNECTED_PARAMS.CONTROLLER}_${id}`,
    (controllerBuilder) => {
      controllerBuilder.configureConnectedComponents(
        `${LIGHTBOX_CONNECTED_PARAMS.ROLE}_${id}`,
        (componentBuilder) => {
          componentBuilder.set({ displayName });
          componentBuilder.behavior();

          componentBuilder
            .gfpp()
            .set('mainAction2', { behavior: 'HIDE' })
            .set('layout', { behavior: 'HIDE' })
            .set('settings', { behavior: 'HIDE' });
        }
      );
    }
  );
};

export const setOloGfpp = (appManifest: AppManifest) => {
  setOloMobileGfpp(appManifest);
};

const setOloMobileGfpp = (appManifest: AppManifest) => {
  const [
    oloStageDataDefault,
    headerStageDataDefault,
    menuStageDataDefault,
    sectionStageDataDefault,
    dishesStageDataDefault,
    navigationStageDataDefault,
  ] = getControllerWidgets(appManifest, [
    OLO_CONTROLLER_WIDGET_ID,
    HEADER_CONTROLLER_WIDGET_ID,
    MENU_CONTROLLER_WIDGET_ID,
    SECTION_CONTROLLER_WIDGET_ID,
    DISHES_CONTROLLER_WIDGET_ID,
    NAVIGATION_CONTROLLER_WIDGET_ID,
  ]);

  const stageDataDefaultArr = [
    oloStageDataDefault,
    headerStageDataDefault,
    ...getChildComponents(headerStageDataDefault, [
      HEADER_WIDGET_COMPONENT_IDS.titleAndDescContainer,
      HEADER_WIDGET_COMPONENT_IDS.badgesContainer,
      HEADER_WIDGET_COMPONENT_IDS.fulfillmentPicker,
      HEADER_WIDGET_COMPONENT_IDS.fulfillmentContainer,
    ]),
    navigationStageDataDefault,
    dishesStageDataDefault,
  ].filter((widget): widget is WidgetStateStageData => !!widget);
  addMobileDesign(stageDataDefaultArr);

  const { gfpp: oloGfpp } = oloStageDataDefault;
  const { gfpp: dishesGfpp } = dishesStageDataDefault;

  addMobileGfpp(oloGfpp as GfppConfig, ['mainAction1', 'mainAction2']);
  const menuComponents = getChildComponents(menuStageDataDefault, [
    MENU_WIDGET_COMPONENT_IDS.menuTitle,
    MENU_WIDGET_COMPONENT_IDS.menuDescription,
  ]);
  menuComponents.forEach((component) => {
    addMobileGfpp(component.gfpp as GfppConfig, ['mainAction2']);
  });
  const sectionComponents = getChildComponents(sectionStageDataDefault, [
    SECTION_WIDGET_COMPONENT_IDS.sectionTitle,
    SECTION_WIDGET_COMPONENT_IDS.sectionDescription,
  ]);
  sectionComponents.forEach((component) => {
    addMobileGfpp(component.gfpp as GfppConfig, ['mainAction2']);
  });
  addMobileGfpp(dishesGfpp as GfppConfig, ['mainAction1', 'iconButtons']);
};

const addMobileDesign = (widgetStateData: (WidgetStateStageData | ConnectedComponentConfig)[]) => {
  widgetStateData.forEach((widget) => {
    widget.gfpp && addMobileGfpp(widget.gfpp, ['widgetDesign']);
  });
};

const addMobileGfpp = (gfppConfig: GfppConfig, gfpps: (keyof GfppDesktopConfig)[]) => {
  if (gfppConfig.desktop && gfppConfig.mobile) {
    gfpps.forEach((gfpp) => {
      // @ts-expect-error
      gfppConfig.mobile[gfpp] = { ...(gfppConfig.desktop as GfppDesktopConfig)[gfpp] };
    });
  }
};

const getChildComponents = (
  widgetState: WidgetStateStageData | ControllerStateStageData,
  elementIds: string[]
) => {
  return elementIds.map((elementId) => widgetState.connections?.[getRole(elementId)] ?? {});
};

const getControllerWidgets = (appManifest: AppManifest, widgetControllerIds: string[]) => {
  return widgetControllerIds.map((widgetId) => getControllerWidgetById(appManifest, widgetId));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type F<A extends any[], R> = (...args: A) => Promise<R>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const monitorCallback = <A extends any[], R>(
  callback: F<A, R>,
  breadcrumb?: {
    type?: string;
    category?: string;
    message?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data?: { [key: string]: any };
  },
  reportError?: ReportError,
  sentry?: ErrorMonitor
): F<A, R> => {
  return (...args: A): Promise<R> => {
    sentry?.addBreadcrumb({ ...breadcrumb, data: { args } });
    try {
      return callback(...args);
    } catch (error) {
      reportError?.(error as Error);
      sentry?.captureException(error as Error);
      return Promise.reject(error);
    }
  };
};
