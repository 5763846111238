import { initState } from '@wix/velocycle-mobx';
import { Pubsub } from '../utils/pubsub';
import type { IModalService } from '../services/modalService';
import type { CartLineItem, ICartService } from '../services/cartService';
import type { Operation } from '../types/businessTypes';
import { DEFAULT_CURRENCY_CODE, DEFAULT_LOCALE } from '../api/consts';
import type { IBIReporterService } from '../services/biReporterService';
import type { IPersistDataService } from '../services/persistDataService';
import type { FedopsLogger } from 'root/utils/monitoring/FedopsLogger';
import { PriceFormattingConverter } from '@wix/restaurants-olo-operations-client-commons';
import type { PriceFormatter } from '@wix/restaurants-olo-operations-client-commons';

export type RootState = {
  cartLineItems: Map<string, CartLineItem[]>;
  pubsub: Pubsub;
  priceFormatter: PriceFormatter;
  ModalService?: IModalService;
  CartService?: ICartService;
  PersistDataService?: IPersistDataService;
  biReporterService?: IBIReporterService;
  operation?: Operation;
  currency: string;
  initialTimeSlotText: string;
  fedopsLogger?: FedopsLogger;
};

export const { state } = initState<RootState>({
  cartLineItems: new Map(),
  pubsub: new Pubsub(),
  currency: DEFAULT_CURRENCY_CODE,
  initialTimeSlotText: '',
  priceFormatter: PriceFormattingConverter.createPriceFormatter(
    DEFAULT_LOCALE,
    DEFAULT_CURRENCY_CODE
  ),
});
