import type { PlatformEvent } from '@wix/editor-platform-sdk-types';
import { APP_DESCRIPTOR_ACTIONS } from './actions';
import { OLODashboardPanel, openManageMenuDashboardPanel } from 'root/utils/openDashboardPanels';
import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { APP_DEF_IDS } from '@wix/restaurants-consts';

const token = 'TOKEN';
export const eventsImpl = async (event: PlatformEvent, editorSDK: FlowEditorSDK) => {
  const { eventPayload } = event;
  switch (eventPayload.actionId) {
    case APP_DESCRIPTOR_ACTIONS.MANAGE_ORDER_SETTINGS: {
      OLODashboardPanel(editorSDK).openSettings();
      break;
    }
    case APP_DESCRIPTOR_ACTIONS.MANAGE_MENUS: {
      openManageMenuDashboardPanel(editorSDK);
      break;
    }
    case APP_DESCRIPTOR_ACTIONS.MANAGE_PAGES: {
      const { check, show } = editorSDK.editor.deeplink;
      const pagesPanel = {
        type: 'pagesPanel' as 'pagesPanel',
        params: [APP_DEF_IDS.restaurants],
      };
      const editorDeepLinkToRestaurantsPagesPanel = await check(token, pagesPanel);
      editorDeepLinkToRestaurantsPagesPanel && (await show(token, pagesPanel));
      break;
    }
    case APP_DESCRIPTOR_ACTIONS.VIEW_ORDERS: {
      OLODashboardPanel(editorSDK).openViewOrders();
      break;
    }
  }
};
