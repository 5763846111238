import type { TFunction } from '@wix/yoshi-flow-editor';
import {
  DISHES_WIDGET_COMPONENT_IDS,
  MENU_WIDGET_COMPONENT_IDS,
  SECTION_WIDGET_COMPONENT_IDS,
  HEADER_WIDGET_COMPONENT_IDS,
  ITEM_MODAL_COMPONENT_IDS,
} from '../../appConsts/blocksIds';
import {
  DISHES_ELEMENTS_IDS,
  MENUS_ELEMENTS_IDS,
  HEADER_ELEMENTS_IDS,
  ITEM_MODAL_ELEMENTS_IDS,
} from '../../appConsts/consts';
import { getRole } from '../../utils/utils';

const getCompsData = (t: TFunction) => {
  const headerCompsData = {
    [HEADER_ELEMENTS_IDS.headerImage]: {
      role: getRole(HEADER_WIDGET_COMPONENT_IDS.headerImage),
      label: t('elements-panel.elements.header-image', 'Image'),
    },
    [HEADER_ELEMENTS_IDS.headerTitle]: {
      role: getRole(HEADER_WIDGET_COMPONENT_IDS.headerTitle),
      label: t('elements-panel.elements.header-title', 'Title'),
    },
    [HEADER_ELEMENTS_IDS.headerDescription]: {
      role: getRole(HEADER_WIDGET_COMPONENT_IDS.headerDescription),
      label: t('elements-panel.elements.header-description', 'Description'),
    },
  };

  const menusCompsData = {
    [MENUS_ELEMENTS_IDS.menuDescription]: {
      role: getRole(MENU_WIDGET_COMPONENT_IDS.menuDescription),
      label: t('elements-panel.elements.menu-description', 'Menu description'),
    },
    [MENUS_ELEMENTS_IDS.sectionTitle]: {
      role: getRole(SECTION_WIDGET_COMPONENT_IDS.sectionTitle),
      label: t('elements-panel.elements.section-title', 'Section title'),
    },
    [MENUS_ELEMENTS_IDS.sectionDescription]: {
      role: getRole(SECTION_WIDGET_COMPONENT_IDS.sectionDescription),
      label: t('elements-panel.elements.section-description', 'Section description'),
    },
    [MENUS_ELEMENTS_IDS.menuDivider]: {
      role: getRole(MENU_WIDGET_COMPONENT_IDS.menuDivider),
      label: t('elements-panel.elements.menu-divider', 'Divider'),
    },
  };

  const dishesCompsData = {
    [DISHES_ELEMENTS_IDS.itemImage]: {
      role: getRole(DISHES_WIDGET_COMPONENT_IDS.itemImage),
      label: t('elements-panel.elements.dish-image', 'Image'),
    },
    [DISHES_ELEMENTS_IDS.itemDescription]: {
      role: getRole(DISHES_WIDGET_COMPONENT_IDS.itemDescription),
      label: t('elements-panel.elements.dish-description', 'Description'),
    },

    [DISHES_ELEMENTS_IDS.labelContainer]: {
      role: getRole(DISHES_WIDGET_COMPONENT_IDS.labelContainer),
      label: t('elements-panel.elements.dish-labels', 'Labels'),
    },
  };

  const itemModalCompsData = {
    [ITEM_MODAL_ELEMENTS_IDS.image]: {
      role: getRole(ITEM_MODAL_COMPONENT_IDS.image),
      label: t('elements-panel.elements.dish-image', 'Image'),
    },
    [ITEM_MODAL_ELEMENTS_IDS.subTitle]: {
      role: getRole(ITEM_MODAL_COMPONENT_IDS.subTitle),
      label: t('elements-panel.elements.dish-description', 'Description'),
    },

    [ITEM_MODAL_ELEMENTS_IDS.labelsContainer]: {
      role: getRole(ITEM_MODAL_COMPONENT_IDS.labelsContainer),
      label: t('elements-panel.elements.dish-labels', 'Labels'),
    },
  };

  return {
    headerCompsData,
    menusCompsData,
    dishesCompsData,
    itemModalCompsData,
  };
};

const getCategoriesIds = (t: TFunction) => ({
  header: t('elements-panel.categories.header', 'Header'),
  menus: t('elements-panel.categories.menus', 'Menu'),
  dishes: t('elements-panel.categories.dishes', 'Items'),
  itemModal: '',
});

const getCategoriesData = (t: TFunction) => {
  const CATEGORIES_IDS = getCategoriesIds(t);
  return {
    header: {
      id: CATEGORIES_IDS.header,
      title: CATEGORIES_IDS.header,
    },
    menus: {
      id: CATEGORIES_IDS.menus,
      title: CATEGORIES_IDS.menus,
    },
    dishes: {
      id: CATEGORIES_IDS.dishes,
      title: CATEGORIES_IDS.dishes,
    },
    itemModal: {
      id: CATEGORIES_IDS.itemModal,
      title: CATEGORIES_IDS.itemModal,
    },
  };
};

const getElementsData = (t: TFunction) => {
  const CATEGORIES_IDS = getCategoriesIds(t);
  const { headerCompsData, menusCompsData, dishesCompsData, itemModalCompsData } = getCompsData(t);

  const headerElementsData = [
    {
      label: headerCompsData.headerImage.label,
      identifier: { role: headerCompsData.headerImage.role },
      categoryId: CATEGORIES_IDS.header,
      index: 0,
    },
    {
      label: headerCompsData.headerTitle.label,
      identifier: { role: headerCompsData.headerTitle.role },
      categoryId: CATEGORIES_IDS.header,
      index: 1,
    },
    {
      label: headerCompsData.headerDescription.label,
      identifier: { role: headerCompsData.headerDescription.role },
      categoryId: CATEGORIES_IDS.header,
      index: 2,
    },
  ];

  const menusElementsData = [
    {
      label: menusCompsData.menuDescription.label,
      identifier: { role: menusCompsData.menuDescription.role },
      categoryId: CATEGORIES_IDS.menus,
      index: 0,
    },
    {
      label: menusCompsData.sectionTitle.label,
      identifier: { role: menusCompsData.sectionTitle.role },
      categoryId: CATEGORIES_IDS.menus,
      index: 1,
    },
    {
      label: menusCompsData.sectionDescription.label,
      identifier: { role: menusCompsData.sectionDescription.role },
      categoryId: CATEGORIES_IDS.menus,
      index: 2,
    },
    {
      label: menusCompsData.menuDivider.label,
      identifier: { role: menusCompsData.menuDivider.role },
      categoryId: CATEGORIES_IDS.menus,
      index: 3,
    },
  ];

  const dishesElementsData = [
    {
      label: dishesCompsData.itemImage.label,
      identifier: { role: dishesCompsData.itemImage.role },
      categoryId: CATEGORIES_IDS.dishes,
      index: 0,
    },
    {
      label: dishesCompsData.itemDescription.label,
      identifier: { role: dishesCompsData.itemDescription.role },
      categoryId: CATEGORIES_IDS.dishes,
      index: 1,
    },
    {
      label: dishesCompsData.labelContainer.label,
      identifier: { role: dishesCompsData.labelContainer.role },
      categoryId: CATEGORIES_IDS.dishes,
      index: 2,
    },
  ];

  const itemModalElementsData = [
    {
      label: itemModalCompsData.proGallery.label,
      identifier: { role: itemModalCompsData.proGallery.role },
      categoryId: CATEGORIES_IDS.itemModal,
      index: 0,
    },
    {
      label: itemModalCompsData.subTitle.label,
      identifier: { role: itemModalCompsData.subTitle.role },
      categoryId: CATEGORIES_IDS.itemModal,
      index: 1,
    },
    {
      label: itemModalCompsData.labelsContainer.label,
      identifier: { role: itemModalCompsData.labelsContainer.role },
      categoryId: CATEGORIES_IDS.itemModal,
      index: 2,
    },
  ];

  return {
    headerElementsData,
    menusElementsData,
    dishesElementsData,
    itemModalElementsData,
  };
};

export const getOloElementsPanelData = (t: TFunction) => {
  const categories = getCategoriesData(t);
  const categoriesData = [categories.header, categories.menus, categories.dishes];
  const { headerElementsData, menusElementsData, dishesElementsData } = getElementsData(t);

  const elementsData = [...headerElementsData, ...menusElementsData, ...dishesElementsData];

  return {
    categoriesData,
    elementsData,
  };
};

export const getHeaderElementsPanelData = (t: TFunction) => {
  const categories = getCategoriesData(t);
  const categoriesData = [categories.header];
  const { headerElementsData } = getElementsData(t);

  const elementsData = [...headerElementsData];

  return {
    categoriesData,
    elementsData,
  };
};

export const getDishesElementsPanelData = (t: TFunction) => {
  const categories = getCategoriesData(t);
  const categoriesData = [categories.dishes];
  const { dishesElementsData } = getElementsData(t);

  const elementsData = [...dishesElementsData];

  return {
    categoriesData,
    elementsData,
  };
};

export const getItemModalElementsPanelData = (t: TFunction) => {
  const categories = getCategoriesData(t);
  const categoriesData = [categories.itemModal];
  const { itemModalElementsData } = getElementsData(t);

  const elementsData = [...itemModalElementsData];

  return {
    categoriesData,
    elementsData,
  };
};
